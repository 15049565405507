import { deepMapKeys } from "@/lib/deepMapKeys"
import axios, { AxiosError, AxiosResponse } from "axios"
import _ from "lodash"
import { getRefreshAccessToken } from '@/api/post'

export const BACKEND_URI = `${import.meta.env.VITE_BACKEND_API_URL}/api`
export const CONTENT_TYPE = "application/json"
export const ACCEPT = "application/json"
export const api = axios.create({
  baseURL: BACKEND_URI,
  headers: {
    "Content-Type": CONTENT_TYPE,
    Accept: ACCEPT
  }
})

export const getTokens = () => {
  const tokensString = localStorage.getItem('tokens');
  const tokens = tokensString ? JSON.parse(tokensString) : {}
  const accessToken = tokens?.access
  const refreshToken = tokens?.refresh

  return {
    accessToken,
    refreshToken
  }
}

const setTokens = (newAccessToken: string, refreshToken: string) => {
  localStorage.setItem('tokens', JSON.stringify({
    access: newAccessToken,
    refresh: refreshToken
  }))
}

export const clearTokens = () => {
  localStorage.removeItem('tokens')
  localStorage.removeItem('userId')
  window.location.href = '/login'
}

api.interceptors.request.use(request => {
  const { accessToken } = getTokens();

  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return request;
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if ((error.response.status === 401 && !originalRequest._retry) && error.response.data.error !== "INVALID_CREDENTIALS") {
      originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
      const { accessToken, refreshToken } = getTokens()

      // Check if we have already refreshed the token once
      if (originalRequest._retryCount > 0) {
        // If we've already retried once, clear the tokens and redirect to the login page
        console.error('Token refresh failed after multiple attempts');
        clearTokens();

        return Promise.reject(error);
      }

      originalRequest._retryCount = (originalRequest._retryCount || 0) + 1;

      try {
        const newAccessToken = await getRefreshAccessToken(accessToken, refreshToken)
        setTokens(newAccessToken, refreshToken)

        // Update the authorization header with the new access token.
        api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        return api(originalRequest); // Retry the original request with the new access token.
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError)
        clearTokens()

        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error); // For all other errors, return the error as is.
  }
);

export const keysToSnakeCase = (data: any) => deepMapKeys(data, _.snakeCase)
export const keysToCamelCase = (data: any) => deepMapKeys(data, _.camelCase)
export const arrayToCamelCase = (data: any[]) =>
  data.map((datum) => deepMapKeys(datum, _.camelCase))
export const arrayToSnakeCase = (data: any[]) =>
  data.map((datum) => deepMapKeys(datum, _.snakeCase))

export interface StandardApiResponse extends Record<string, any> {
  isError?: boolean
  error?: string
}

// THIS IS REDUNDANT
export const AuthHeader = (accessToken: string) => ({
  // headers: {
  //   Authorization: `Bearer ${accessToken}`
  // }
})

export const StandardGetHandler =
  <D>(
    dataKey: string = "data"
  ): ((response: AxiosResponse<D>) => StandardApiResponse) =>
  (response) => {
    if (response.status < 200 || response.status > 299) {
      return {
        isError: true,
        error: `Server responded with error code ${response.status}`
      }
    }
    return Array.isArray(response.data) ?
        {
          [dataKey]: arrayToCamelCase(response.data)
        }
      : {
          [dataKey]: keysToCamelCase(response.data)
        }
  }

export const StandardPushHandler =
  <D>(
    dataKey?: string
  ): ((response: AxiosResponse<D>) => StandardApiResponse) =>
  (response: AxiosResponse): StandardApiResponse => {
    if (response.status < 200 || response.status > 299) {
      return {
        isError: true,
        error: `Server responded with error code ${response.status}`
      }
    }
    return dataKey ?
        {
          isError: false,
          [dataKey]: response.data
        }
      : {
          isError: false
        }
  }

export const StandardErrorHandler = (
  error: AxiosError
): StandardApiResponse => {
  if (error.response && error.response.status === 401) {
    throw error
  }
  return {
    isError: true,
    error: error.message
  }
}
