import { CarouselItem } from "@/components/ui/carousel"
import * as Alert from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { Badges } from "@/components/custom/Badges"
import { formatCheckinCheckout } from "@/lib/formatCheckinCheckout"

const spotOrLot = (result: any) => result?.lot ? result?.lot : result?.spot

const availableCarouselItems = (results: any, setDialogOpen?: any, scrollToCarousel?: any) => (
  results?.map((result: any, index: number) => (
    <CarouselItem
      key={index}
      className="pl-2 basis-4/5 cursor-pointer"
      onClick={() => {
        setDialogOpen(true)
        scrollToCarousel(result.facility.id, index)
      }}
    >
      <div className="bg-white rounded flex h-full">
        {
          result?.facility?.images[0]?.file && (
            <img 
              className="h-28 w-28 square bg-contain rounded-l" 
              src={result?.facility?.images[0]?.file} 
              alt={result?.facility?.name}
            />
          )
        }
        <ul className="p-2 truncate w-full flex flex-col">
          <li className="font-medium truncate">
            {result?.facility?.name}
          </li>
          <li className="flex flex-wrap">
            {Badges(spotOrLot(result).tags, "scale-90 mr-0")}
          </li>
          <li className="flex font-semibold mt-auto items-center">
            ${parseFloat(result.price).toFixed(0)}
            <span className="font-normal text-sm">/total</span>
          </li>
        </ul>
      </div>
    </CarouselItem>
  ))
)

// TODO: Open pop-up when clicking on unavailable item
const availabilityPopup = (result: any) => (
  <Alert.AlertDialog>
    <Alert.AlertDialogTrigger asChild>
      <Button className="m-2">View Availability</Button>
    </Alert.AlertDialogTrigger>
    <Alert.AlertDialogContent>
      <Alert.AlertDialogHeader>
        <Alert.AlertDialogTitle className="text-left">{result.facility.name}</Alert.AlertDialogTitle>
        <Alert.AlertDialogDescription className="text-left">
          <span className="block text-sm pb-2 text-black text-left">If you would like to continue with your reservation for this facility, please update your search dates within the availability shown.</span>
          {
            result.availability.availableOnDates.length > 0 && (
              <>
                <span className="block text-black mb-1">Available Dates:</span>
                <ul className="p-2 w-full flex flex-col bg-gray-100 rounded text-black mb-2">
                  {result.availability.availableOnDates.map((date: any) => (
                    <li className="font-normal text-sm" key={date}>- {formatCheckinCheckout(date)}</li>
                  ))}
                </ul>
              </>
            )
          }
          {
            result.availability.unavailableOnDates.length > 0 && (
              <>
                <span className="block text-black mb-1">Unavailable Dates:</span>
                <ul className="p-2 w-full flex flex-col bg-gray-100 rounded text-black">
                  {result.availability.unavailableOnDates.map((date: any) => (
                    <li className="font-normal text-sm" key={date}>- {formatCheckinCheckout(date)}</li>
                  ))}
                </ul>
              </>
            )
          }
        </Alert.AlertDialogDescription>
      </Alert.AlertDialogHeader>
      <Alert.AlertDialogFooter>
        <Alert.AlertDialogAction>Close</Alert.AlertDialogAction>
      </Alert.AlertDialogFooter>
    </Alert.AlertDialogContent>
  </Alert.AlertDialog>
)

const unavailableCarouselItems = (results: any) => (
  results?.map((result: any, index: number) => (
    <CarouselItem key={index} className="pl-2 basis-4/5 cursor-pointer">
      <div className="bg-white rounded flex flex-col h-full justify-between">
        <ul className="p-2 truncate w-full flex flex-col">
          <li className="font-medium truncate flex justify-between">
            {result?.facility?.name}
          </li>
          <li className="flex flex-wrap gap-1">
            {Badges(spotOrLot(result).tags)}
          </li>
        </ul>
        {availabilityPopup(result)}
      </div>
    </CarouselItem>
  ))
)

export const CarouselSearchResults = ({
  searchResults,
  setDialogOpen,
  scrollToCarousel,
}: {
  searchResults: any[],
  setDialogOpen: () => {},
  scrollToCarousel: () => {},
}) => {
  const availableResults = searchResults?.filter((result: any) => result.isAvailable)
  const unavailableResults = searchResults?.filter((result: any) => !result.isAvailable)

  return (
    <>
      {availableCarouselItems(availableResults, setDialogOpen, scrollToCarousel)}
      {unavailableCarouselItems(unavailableResults)}
    </>
  )
}
