import { useMemo, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card"
import { TypographyP } from "@/components/ui/typography/p"
import { Input } from "@/components/ui/input"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { userRoles } from "@/constants/constants"
import { register } from "@/api/post"
import { getCoupon } from "@/api/get"
import {
  requiredPassword,
  requiredString,
  requiredEmail,
  contactSchema
} from "@/constants/formSchema"
import { FormPhoneNumberInput } from "@/components/custom/Forms/FormInputs"
import { Checkbox } from "@/components/ui/checkbox"

const signUpFormSchema = contactSchema.merge(
  z.object({
    firstName: requiredString,
    lastName: requiredString,
    email: requiredEmail,
    password: requiredPassword,
    signupCode: z.string().optional(),
    marketingOptIn: z.boolean().optional()
  })
)

const defaultValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  password: "",
  signupCode: "",
  marketingOptIn: true
}

const SignUp = () => {
  const [description, setDescription] = useState(null)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const promoCode = useMemo(async () => {
    if (!searchParams.size) return "NEW20"

    const { data } = await getCoupon(searchParams.get("promo"))
    setDescription(data.description)

    return searchParams.get("promo") ?? ""
  }, [searchParams])

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    ...form
  } = useForm({
    resolver: zodResolver(signUpFormSchema),
    defaultValues
  })

  const onSubmit = async (formData) => {
    try {
      // TODO: Assume all users are PARKER role for now
      const dataToSubmit = {
        ...formData,
        signupCode: await promoCode,
        role: userRoles.PARKER
      }

      await register(dataToSubmit)

      navigate("/login")
    } catch (error) {
      if (error.response?.status === 400) {
        setError({
          ...error,
          message:
            "A user with this email already exists. Did you mean to log in?"
        })
      } else {
        setError({ ...error, message: "An error occurred. Please try again." })
      }
    }
  }

  return (
    <div className="px-4 lg:px-10 py-6">
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="mx-auto max-w-sm">
            <CardHeader>
              <CardTitle className="text-2xl">Sign up</CardTitle>
              <p>Create a free account.</p>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <FormField
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="min-h-[100px]">
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Your First Name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="min-h-[100px]">
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Your Last Name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormPhoneNumberInput
                control={control}
                name="phone"
                type="phone"
                label="Phone "
                placeholder="Phone Number"
                isSignUp
                className="min-h-[100px]"
              />
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormItem className="min-h-[100px]">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="Your Email" {...field} autoComplete="on" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="password"
                render={({ field }) => (
                  <FormItem className="min-h-[100px]">
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Password"
                        type="password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {
                description && (
                  <div className="w-full py-4">
                    <TypographyP className="text-sm italic">
                      {description}
                    </TypographyP>
                  </div>
                )
              }

              <FormField
                control={control}
                name="marketingOptIn"
                render={({ field }) => (
                  <FormItem className="flex flex-row space-x-2 space-y-0 ">
                    <FormControl> 
                      <Checkbox {...field} checked={field.value} onCheckedChange={field.onChange}/>
                    </FormControl>
                    <FormLabel className="flex items-center gap-3">
                      <p>Yes, I’d like to receive updates and offers.</p>
                    </FormLabel>
                  </FormItem>
                )}
              />

              <div className="grid gap-4 mt-6">
                {error && error.message ?
                  <div>
                    <p className="text-red-500 text-sm">{error.message}</p>
                  </div>
                : null}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="w-full">
                  Sign up
                </Button>
              </div>
              <div className="mt-4 text-center text-sm">
                Already have an account?{" "}
                <Link to="/login" className="underline">
                  Log in
                </Link>
              </div>
            </CardContent>
            <CardFooter >
              <div className="space-y-2 text-center w-full">
                <p className="text-[10px] text-gray-500">
                  By proceeding, you agree to our{" "}
                  <a href="/terms-and-conditions" className="text-blue-500">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="/terms-and-conditions" className="text-blue-500">
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </div>
  )
}

export default SignUp

