import { Link, useNavigate } from "react-router-dom"
import { Button } from "@/components/ui/button"
import Icon from "@/components/custom/Icon"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import TypographyH1 from "@/components/ui/typography/h1"
import { useParkerReservations } from "@/hooks/useParkerReservations"
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import NoReservations from "@/components/custom/NoReservations"
import ReservationCard from "@/components/custom/ReservationCard"
import { useState } from "react"

const ParkerReservationsPage = () => {
  const navigate = useNavigate()
  const [cancelledIds, setCancelledIds] = useState(new Set())

  const {
    dataIsPending,
    dataError,
    upcomingReservations,
    previousReservations,
    cancelledReservations,
    parkerName
  } = useParkerReservations()

  if (dataIsPending) return <Loading />

  if (dataError) return <Fallback />

  const handleCancellation = (reservationId) => {
    setCancelledIds(prev => new Set([...prev, reservationId]))
  }

  const upcoming = upcomingReservations
    .filter(reservation => !cancelledIds.has(reservation.id))
    .sort((a, b) => new Date(b.checkinDatetime) - new Date(a.checkinDatetime))
    .map((reservation) => (
      <ReservationCard
        reservationData={reservation}
        cancellable={true}
        tippable={true}
        title=""
        key={reservation.id}
        onCancellation={handleCancellation}
      />
    ))

  const previous = previousReservations
    .sort((a, b) => new Date(b.checkinDatetime) - new Date(a.checkinDatetime))
    .map((reservation) => (
      <ReservationCard
        reservationData={reservation}
        title=""
        tippable={true}
        key={reservation.id}
      />
    ))

  const cancelled = cancelledReservations
    .sort((a, b) => new Date(b.checkinDatetime) - new Date(a.checkinDatetime))
    .map((reservation) => (
      <ReservationCard
        reservationData={reservation}
        title="cancelled"
        key={reservation.id}
      />
    ))

  const welcome = (
    <div className="space-y-0.5">
      <TypographyH1 className="flex items-center pb-2">
        <Icon
          name="calendar-days"
          size={24}
          className="lg:size-8 stroke-1 mr-3  "
        />
        Reservations
      </TypographyH1>
    </div>
  )

  const allReservations = (
    <Tabs defaultValue="upcoming">
      <div className="flex items-center">
        <TabsList>
          <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
          <TabsTrigger value="previous">Previous</TabsTrigger>
          <TabsTrigger value="cancelled">Cancelled</TabsTrigger>
        </TabsList>
      </div>
      <TabsContent value="upcoming">
        {upcoming.length > 0 ?
          upcoming
        : <NoReservations message="No Upcoming Reservations" />}
      </TabsContent>
      <TabsContent value="previous">
        {previous.length > 0 ?
          previous
        : <NoReservations message="No Previous Reservations" />}
      </TabsContent>
      <TabsContent value="cancelled">
        {cancelled.length > 0 ?
          cancelled
        : <NoReservations message="No cancelled Reservations" />}
      </TabsContent>
    </Tabs>
  )

  return (
    <main className="space-y-6 p-10 pb-16">
      <div className="flex flex-col justify-center">
        {welcome}
        {allReservations}
      </div>
    </main>
  )
}

export default ParkerReservationsPage
