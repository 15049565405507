import { Card, CardContent, CardHeader } from "../ui/card"
import { Separator } from "@/components/ui/separator"
import { TypographyH4 } from "../ui/typography/h4"

export const UnavailableParkSearchResult = ({ data }) => {
  const {
    facility: {
      name: facilityName,
      address: { address1, address2, city, state, zipCode },
      description: facilityDescription,
      amenities,
    },
    parkingLocationType,
    images,
    price
  } = data

  const formatDate = (date) => {
    const d = new Date(date + 'T00:00:00')
    
    return d.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    })
  }

  return (
    <Card className="shadow-lg">
      <CardHeader className="relative flex flex-row justify-between px-6 py-2">
        <div className="overflow-hidden">
          <TypographyH4 className="capitalize font-semibold text-2xl truncate p-0">
            {facilityName}
          </TypographyH4>
        </div>
      </CardHeader>
      <Separator />
      <CardContent className="flex flex-col gap-2 lg:p-6">
        {
          data.availability.availableOnDates.length > 0 && (
            <>
              Available Dates:
              <ul className="p-2 w-full flex flex-col bg-gray-100 rounded">
                {data.availability.availableOnDates.map((date) => (
                  <li className="font-normal text-sm" key={date}>- {formatDate(date)}</li>
                ))}
              </ul>
            </>
          )
        }
        {
          data.availability.unavailableOnDates.length > 0 && (
            <>
              Unavailable Dates:
              <ul className="p-2 w-full flex flex-col bg-gray-100 rounded">
                {data.availability.unavailableOnDates.map((date) => (
                  <li className="font-normal text-sm" key={date}>- {formatDate(date)}</li>
                ))}
              </ul>
            </>
          )
        }
        <p className="text-sm">If you would like to continue with your reservation for this facility, please update your booking with the unavailability above in mind.</p>
      </CardContent>
    </Card>
  )
}
