import Root from "@/components/custom/Root"
import { OwnerRoutes } from "@/dashboards/Owner/OwnerRoutes"
import { ParkerRoutes } from "@/dashboards/Parker/ParkerRoutes"
import { PublicRoutes } from "@/pages/PublicRoutes"
import { MarketRoutes } from "@/pages/Market/Routes"

const ShowOwnerDashboard =
  import.meta.env.VITE_SHOW_OWNER_DASHBOARD === true ||
  import.meta.env.VITE_SHOW_OWNER_DASHBOARD === "true"

const roleRoutes = (auth: any) => [
  {
    path: "/",
    element: <Root />,
    children:
      ShowOwnerDashboard ?
        [
          ...PublicRoutes,
          ParkerRoutes("parker-dashboard/*", auth),
          OwnerRoutes("owner-dashboard/*", auth),
        ]
      : [
          ...PublicRoutes,
          ParkerRoutes("parker-dashboard/*", auth),
        ],
  }
]

export const routes = (auth: any) => [
  ...roleRoutes(auth),
  ...(auth?.user?.features?.includes("MARKET_PLACE") ? MarketRoutes : []),
]
