  import { useState } from "react"
  import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription
  } from "@/components/ui/dialog"
  import { Separator } from "@/components/ui/separator"
  import Icon from "@/components/custom/Icon"
  import ImageCarousel from "./ImageCarousel"
  import { Button } from "../ui/button"
  import { addressToMapLink } from "@/lib/addressToMapLink"
  import { Badges } from "@/components/custom/Badges"
  import { useSearchFlow } from "@/hooks/useSearchFlow"
  import { cn } from "@/lib/utils"
  import { formatCheckinCheckout } from "@/lib/formatCheckinCheckout"

  const FacilityDetailsDialog = ({ dialogTrigger, data, dialogOpen, closeDialog }) => {
    const [open, setOpen] = useState(false)
    const { reserveSpot } = useSearchFlow()
    const isPhone = /Android|webOS|iPhone/i.test(navigator.userAgent)

    if (!data) return null

    const {
      facility: {
        name: facilityName,
        address,
        description: facilityDescription,
        amenities: complimentaryAmenities,
        pricing,
        paidAmenities
      },
      parkingLocationType,
      images,
      spot,
      lot
    } = data

    const parkingId = parkingLocationType === "SPOT" ? spot.id : lot.id
    const parkingSpot = parkingLocationType === "SPOT" ? spot : lot
    const lotTags = lot?.tags.sort((a, b) => (a.displayOrder) - (b.displayOrder))
    const spotTags = spot?.tags.sort((a, b) => (a.displayOrder) - (b.displayOrder))

    const handleBookSpot = () => {
      setOpen(false)
      reserveSpot(parkingId, data)
    }

    const amenitiesBlock = (amenities, title) => {
      if (amenities?.length === 0) return null

      return (
        <div className="grid gap-3">
          <p className="text-sm font-semibold">{title}</p>
          <div className="grid grid-cols-3 gap-2">
            {
              amenities.map((amenity, i) => {
                return (
                  <div key={i} className="flex flex-row items-center">
                    {amenity.iconName && <Icon name={amenity.iconName} size={20} className="shrink-0 mr-2" />}
                    <p className="text-sm">{amenity.name}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    }

    return (
      <Dialog key={parkingId} open={open || dialogOpen} onOpenChange={(newOpen) => {
        if (closeDialog) {
          setOpen(newOpen)
          if (!newOpen) closeDialog()
        }
      }}>
        {dialogTrigger}
        <DialogContent
          className="translate-y-0 overflow-scroll max-h-full p-0 border-none top-0"
          closeButtonStyles={"bg-white rounded-full opacity-100 right-2 top-2"}
          closeButtonIcon={"h-6 w-6 right-2"}
        >
          {images.length > 0 && (
            <ImageCarousel
              images={images}
              itemClassName="overflow-visible h-[250px] z-10"
              arrowClasses="scale-75 bg-white border-none z-50"
              overflowOverride={cn("", isPhone && "overflow-x-visible overflow-y-hidden")}
            />
          )}
          <div className="px-4 py-2">
            <DialogHeader>
              <DialogTitle className="flex flex-row flex-wrap justify-between text-left">
                <span className="mb-1">{facilityName}</span>
                <span>${parseFloat(data.price).toFixed(0)}<span className="font-normal text-sm">/total</span></span>
              </DialogTitle>
              <div className="flex justify-between text-xs">
                <span className="flex flex-col text-left">
                  <span>Check In:</span>
                  <span>{formatCheckinCheckout(data.checkinDate, "PP")}</span>
                </span>
                <span className="flex flex-col text-right">
                  <span>Check Out:</span>
                  <span>{formatCheckinCheckout(data.checkoutDate, "PP")}</span>
                </span>
              </div>
              <div className="flex text-left py-2 mb-1">
                <div className="flex flex-1 flex-col bg-gray-200 p-2 rounded-l border-r border-gray-300">
                  <span className="text-sm">Enter At:</span>
                  <span className="font-medium">6:00 AM</span>
                </div>
                <div className="flex flex-1 flex-col bg-gray-200 p-2 rounded-r">
                  <span className="text-sm">Leave By:</span>
                  <span className="font-medium">6:00 PM</span>
                </div>
              </div>
              <div className="flex">
                {Badges(spotTags, "capitalize mr-1")}
                {Badges(lotTags, "capitalize mr-1")}
              </div>
            </DialogHeader>
            <div className="grid gap-4">
              {facilityDescription && (
                <div>
                  <DialogDescription className="text-left text-primary text-sm mt-4">
                    {facilityDescription}
                  </DialogDescription>
                  <Separator className="mt-4 opacity-50" />
                </div>
              )}
              {
                (complimentaryAmenities.length > 0 || paidAmenities.length > 0) && (
                  <div className="grid gap-6">
                    {complimentaryAmenities.length > 0 && (amenitiesBlock(complimentaryAmenities, "Complimentary Amenities"))}
                    {paidAmenities.length > 0 && (amenitiesBlock(paidAmenities, "Paid Amenities"))}
                    <Separator className="opacity-50" />
                  </div>
                )
              }
              <div className="grid gap-3">
                <p className="text-sm font-semibold">Things You Should Know</p>
                <ul className="text-sm">
                  {
                    parkingSpot.maxHeightSupported ? (
                      <li>Max height supported: {parkingSpot.maxHeightSupported} ft.</li>
                    ) : (
                      <li>Standard size vehicles only</li>
                    )
                  }
                </ul>
              </div>
              <Separator className="opacity-50" />
              <div className="grid gap-3">
                <p className="text-sm font-semibold">Parking Instructions</p>
                <p className="text-sm">{parkingSpot.parkerInstructions}</p>
              </div>
              <Separator className="opacity-50" />
              <div className="grid gap-3 mb-1">
                <p className="text-sm font-semibold">Location</p>
                  <address className="flex gap-1 not-italic tracking-tight text-sm underline underline-offset-2">
                    <Icon name="map-pin" size={20} className="mr-1" />
                    <a href={addressToMapLink(address)}>
                      {address.address1}
                      {address.address2 && <p>{address.address2}</p>}
                      {' ' + address.city}, {' ' + address.state} {address.zipCode}
                    </a>
                  </address>
              </div>
            </div>
            <div className="sticky bg-white bottom-0 py-2 w-full">
              <span className="block font-semibold py-1">${parseFloat(data.price).toFixed(0)}<span className="font-normal">/total</span></span>
              <Button className="w-full" onClick={handleBookSpot}>
                Book Spot
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  export default FacilityDetailsDialog
