import { useState, useEffect } from "react"
import { emailSignup } from "@/api/post"

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

interface ValidationResult {
  isValid: boolean
  error: string
}

interface FormEvent extends React.FormEvent<HTMLFormElement> { }

interface useEmailSignupProps {
  storageKey?: string;
}

export const useEmailSignup = ({ storageKey }: useEmailSignupProps = {}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [emailError, setEmailError] = useState("")

  useEffect(() => {
    if (!storageKey) return
    const hasVisited = localStorage.getItem(storageKey)

    if (hasVisited === "true") {
      setIsOpen(false)
    } else {
      setTimeout(() => {
        setIsOpen(true)
      }, 5000)
    }
  }, [])

  const validateEmail = (email: string): ValidationResult => {
    if (!email) {
      return { isValid: false, error: "Email is required" }
    }
    if (!EMAIL_REGEX.test(email)) {
      return { isValid: false, error: "Please enter a valid email address" }
    }
    return { isValid: true, error: "" }
  }

  const setLocalStorageCode = (localStorageCode: string, bool: boolean) => {
    localStorage.setItem(localStorageCode, bool.toString())
  }

  const handleClose = () => {
    setIsOpen(false)
    if (storageKey) {
      setLocalStorageCode(storageKey, true)
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const { isValid, error } = validateEmail(email)
    setEmailError(error)

    if (!isValid) return false


    try {
      await emailSignup({ email })
      setIsConfirmed(true)
      setEmail("")
      setEmailError("")
      if (storageKey) {
        setLocalStorageCode(storageKey, true)
      }
      return true
    } catch (error) {
      setEmailError("Failed to sign up. Please try again.")
      return false
    }
  }

  return {
    isOpen,
    email,
    setEmail,
    emailError,
    isConfirmed,
    handleClose,
    handleSubmit,
  }
}