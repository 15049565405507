import { useMemo, useState, useEffect } from "react"
import { Map, useMap, useMapsLibrary, InfoWindow } from "@vis.gl/react-google-maps"
import MapMarker from "./MapMarker"
import InfoWindowContent from "./InfoWindowContent"
import { useBreakpoint } from "@/hooks/useBreakpoint"

// Vail Area
const INITIAL_CENTER = { lat: 39.685746181767016, lng: -106.60881994540598 }
// Street view
const DEFAULT_ZOOM = 10

const ParkingSpotsMap = ({ markers = [], scrollToCarousel, activeCardIndex }) => {
  const [infoWindowRef, setInfoWindowRef] = useState(null)
  const [infoWindowContent, setInfoWindowContent] = useState(null)
  const [activeMarkerId, setActiveMarkerId] = useState(null)
  const mapId = import.meta.env.VITE_GOOGLE_MAP_ID_LANDING_PAGE
  const map = useMap()
  const coreLib = useMapsLibrary("core")
  const bounds = useMemo(() => coreLib && new coreLib.LatLngBounds(), [coreLib])
  const desktopBreakpoints = ["lg", "xl", "2xl"]
  const breakpoint = useBreakpoint()
  const isDesktop = desktopBreakpoints.includes(breakpoint)

  useEffect(() => {
    if (!bounds || !map || !markers.length) return

    for (const marker of markers) {
      bounds.extend(marker.position)
    }

    setActiveMarkerId(markers[activeCardIndex]?.facility?.id)

    const lat = parseFloat(markers[activeCardIndex]?.location?.latitude)
    const lng = parseFloat(markers[activeCardIndex]?.location?.longitude)
    const newCenter = {
      lat: isFinite(lat) ? lat : INITIAL_CENTER.lat,
      lng: isFinite(lng) ? lng : INITIAL_CENTER.lng
    }

    map.setCenter(newCenter)
  }, [bounds, map, markers])

  const handleClose = () => {
    setInfoWindowRef(null)
    setInfoWindowContent(null)
  }

  const handleMarkerClick = (markerRef, markerInfo) => {
    setActiveMarkerId(markerInfo?.facility?.id)

    if (isDesktop) {
      setInfoWindowRef(markerRef)
      setInfoWindowContent(markerInfo)
    }

    if (!isDesktop) {
      const index = markers.findIndex((marker) => marker.facility?.id === markerInfo?.facility?.id)
      scrollToCarousel(markerInfo?.facility?.id, index);
    }
  }

  return (
    <div className="w-full h-full rounded-xl lg:shadow-xl">
      <Map
        mapId={mapId}
        defaultCenter={INITIAL_CENTER}
        defaultZoom={DEFAULT_ZOOM}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
      >
        {markers.map((marker, index) => {
          return (
            <MapMarker
              key={index}
              markerInfo={marker}
              handleMarkerClick={handleMarkerClick}
              isActive={marker.facility?.id === activeMarkerId}
            />
          )
        })}
        <InfoWindow
          anchor={infoWindowRef}
          maxWidth={300}
          closeBoxMargin
          onClose={handleClose}
          onCloseClick={handleClose}
          options={{
            headerDisabled: true
          }}>
          {infoWindowContent && (
            <InfoWindowContent
              handleClose={handleClose}
              markerInfo={infoWindowContent}
            />
          )}
        </InfoWindow>
      </Map>
    </div>
  )
}

export default ParkingSpotsMap
