import ParkSearchResult from "./ParkSearchResult"
import { UnavailableParkSearchResult } from "./UnavailableParkSearchResult"

const ParkSearchResults = ({ searchResults, reserveSpot, availableResults, unavailableResults }) => {
  const parkSearchResults = availableResults.map((data, i) => (
    <ParkSearchResult data={data} key={i} reserveSpot={reserveSpot} />
  ))

  const unavailableParkSearchResults = unavailableResults.map((data, i) => (
    <UnavailableParkSearchResult key={i} data={data} />
  ))

  return (
    <div className="flex flex-col gap-4 bg-[#FFFFFFC4]">
      {parkSearchResults}
      {unavailableParkSearchResults}
    </div>
  )
}

export default ParkSearchResults
