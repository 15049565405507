import { AddressRow } from "@/components/custom/Address/AddressRow"
import { Separator } from "@/components/ui/separator"
import { SheetDescription, SheetHeader, SheetTitle } from "@/components/ui/sheet"
import { getFormattedDateTime } from "@/lib/utils"
import type { Reservation } from "@/api/types/internal/Reservation"

export interface ReservationSliderProps {
  reservation: Reservation
}

export const ReservationSlider = ({ reservation }: ReservationSliderProps) => {
  const spotSurferUser = reservation.parker.user
  const [_, lastUpdatedDate] = getFormattedDateTime(reservation.updatedDatetime)
  return (
    <>
      <SheetHeader className="flex flex-row items-start">
        <div className="grid gap-0.5">
          <SheetTitle className="group flex items-center gap-2 text-lg">
            Reservation for {spotSurferUser?.firstName}{" "}
            {spotSurferUser?.lastName}
          </SheetTitle>
          <SheetDescription>
            <CheckInOutLabel
              checkInDateTime={reservation.checkinDatetime}
              checkOutDateTime={reservation.checkoutDatetime}
            />
          </SheetDescription>
        </div>
      </SheetHeader>
      <div className="py-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Reservation Details</div>
          <ul className="grid gap-3 grid-cols-1">
            <li className="items-center justify-between">
              <p className="font-semibold">
                {reservation.parkingLocationType === "SPOT" ?
                  reservation.spot?.name
                : reservation.lot?.name}
              </p>
              <p className="font-normal">{reservation.facility?.name}</p>
              <AddressRow
                className="text-muted-foreground"
                address={reservation.facility.address}
              />
              <span>${reservation.order?.finalAmount}</span>
            </li>
          </ul>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Customer Information</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt>Customer</dt>
              <dd>
                {spotSurferUser.firstName} {spotSurferUser.lastName}
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt>Email</dt>
              <dd>
                {spotSurferUser.email}
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt>Phone</dt>
              <dd>
                <a href="tel:">{spotSurferUser.phone}</a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <Separator className="my-4" />
      <div className="grid gap-3">
        <div className="font-semibold">Vehicle</div>
        <ul className="grid gap-3">
          <li className="flex items-center justify-between">Make: <span>{reservation.vehicle?.make}</span></li>
          <li className="flex items-center justify-between">Model: <span>{reservation.vehicle?.model}</span></li>
          <li className="flex items-center justify-between">Type: <span>{reservation.vehicle?.type}</span></li>
          <li className="flex items-center justify-between">Color: <span>{reservation.vehicle?.color}</span></li>
          <li className="flex items-center justify-between">License Plate #: <span>{reservation.vehicle?.licenseNumber}</span></li>
        </ul>
      </div>
      <Separator className="my-4" />
      <div className="grid gap-3">
        <div className="font-semibold">Add-ons</div>
        <ul className="grid gap-3">
          {reservation.paidAmenities.map((item: any) => (
            <li className="flex items-center justify-between" key={item.id}>{item.name}</li>
          ))}
        </ul>
      </div>
      <div className="flex flex-row items-center border-t bg-muted/50 mt-4 py-3">
        <div className="text-xs text-muted-foreground">
          Updated{" "}
          <time dateTime={reservation.updatedDatetime}>{lastUpdatedDate}</time>
        </div>
        {/* <Pagination className="ml-auto mr-0 w-auto">
          <PaginationContent>
            <PaginationItem>
              <Button size="icon" variant="outline" className="h-6 w-6">
                <ChevronLeft className="h-3.5 w-3.5" />
                <span className="sr-only">Previous Reservation</span>
              </Button>
            </PaginationItem>
            <PaginationItem>
              <Button size="icon" variant="outline" className="h-6 w-6">
                <ChevronRight className="h-3.5 w-3.5" />
                <span className="sr-only">Next Reservation</span>
              </Button>
            </PaginationItem>
          </PaginationContent>
        </Pagination> */}
      </div>
    </>
  )
}

interface CheckInOutLabelProps {
  checkInDateTime: string
  checkOutDateTime: string
}

const CheckInOutLabel = ({
  checkInDateTime,
  checkOutDateTime
}: CheckInOutLabelProps) => {
  const [checkInTimeFormatted, checkInDateFormatted] = getFormattedDateTime(checkInDateTime)
  const [checkOutTimeFormatted, checkOutDateFormatted] = getFormattedDateTime(checkOutDateTime)

  if (checkInDateFormatted === checkOutDateFormatted) {
    return (
      <div className="grid gap-1">
        <div>{checkInDateFormatted}</div>
        <div>
          {checkInTimeFormatted} - {checkOutTimeFormatted}
        </div>
      </div>
    )
  } else {
    return (
      <div className="grid grid-cols-[auto_auto_auto] items-center gap-6">
        <div className="grid gap-1">
          <div>{checkInDateFormatted}</div>
          <div>{checkInTimeFormatted}</div>
        </div>
        <span>-</span>
        <div className="grid gap-1">
          <div>{checkOutDateFormatted}</div>
          <div>{checkOutTimeFormatted}</div>
        </div>
      </div>
    )
  }
}

