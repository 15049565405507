import { UpdateSpotAvailability } from "@/api/spots/UpdateSpotAvailability"
import { Spot } from "@/api/types/internal/Spot"
import { availabilityToBackendState } from "@/components/custom/Forms/AvailabilityGenerics/const"
import { EditAvailabilityTab } from "@/components/custom/Forms/UpdateFormGenerics/EditAvailabilityTab"
import { UpdateFormTabsWrapperChildren } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { AvailabilityForm } from "@/constants/AvailabilitySchema"
import { useAuth } from "@/context/AuthContext"
import _ from "lodash"
import { useCallback } from "react"
import { useParams } from "react-router-dom"

export const EditSpotAvailability = ({
  data,
  ...tabProps
}: UpdateFormTabsWrapperChildren<Spot, AvailabilityForm>) => {
  const { accessToken } = useAuth()
  const { spotId } = useParams()

  const submitHandler = useCallback(
    (result: AvailabilityForm) => {
      if (spotId) {
        return UpdateSpotAvailability(
          accessToken,
          spotId,
          availabilityToBackendState(result)
        )
      }
    },
    [accessToken, spotId, data]
  )

  return (
    <EditAvailabilityTab
      data={data}
      submitHandler={submitHandler}
      {...tabProps}
    />
  )
}
