import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import spotSurferIcon from "@/assets/pngs/spotSurfer.png"
import TEMPORARYAllPaths from "@/components/custom/TEMPORARYAllPaths"
import UserDropdownMenu from "@/components/custom/UserDropdownMenu"
import SideNavigation from "@/components/custom/SideNavigation"
import { ErrorBoundary } from "react-error-boundary"
import Fallback from "./Fallback"
import { logError } from "@/lib/logError"
import { TypographyH2 } from "../ui/typography/h2"
import { cn } from "@/lib/utils"
import { useAuth } from "@/context/AuthContext"
import { userRoles } from "@/constants/constants"
import Icon from "./Icon"
import PublicHeader from "@/components/custom/PublicHeader"

const DashboardFrame = ({ menuItems }) => {
  const { user, logout } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleLogout = async () => {
    await logout()
    navigate("/")
  }

  const desktopMenuItems = menuItems.map(
    ({ name, icon, path, canDisplay }, i) =>
      canDisplay ?
        <Link
          key={i}
          to={path}
          className={cn(
            "flex items-center gap-3 rounded-lg px-3 py-2 text-white transition-all hover:text-accent-yellow",
            {
              "bg-muted/40 opacity-70": pathname === path
            }
          )}>
          <Icon name={icon} className="h-4 w-4" />
          {name}
        </Link>
      : null
  )

  return (
    <div className="bg-background grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
      <PublicHeader bg={"bg-primary"} dashBoard={true} dashboardNavItems={menuItems} />
      <div className="relative hidden text-white lg:block">
        <div className="flex h-[calc(100vh-64px)] flex-col gap-2 fixed w-[280px] top-[64px]">
          <div className="flex-1 bg-primary">
            <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
              {desktopMenuItems}
              {
                user?.role === userRoles.PARKER && (
                  <Link
                    className="flex items-center gap-3 rounded-lg px-3 py-2 text-white transition-all hover:text-accent-yellow"
                    to="/parking-packs" 
                  >
                    <Icon name="square-parking" className="h-4 w-4" />
                    Discount Parking
                  </Link>
                )
              }
              <Link
                onClick={handleLogout}
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-white transition-all hover:text-accent-yellow"
              >
              <Icon name="log-out" className="h-4 w-4" />
              Log out
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
          <div className="relative top-[64px]">
            <Outlet />
          </div>
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default DashboardFrame
