import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table"
import { FacilitiesTableRow } from "./FacilitiesTableRow"
import { useCallback, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { AddressRow } from "@/components/custom/Address/AddressRow"
import { Button } from "@/components/ui/button"
import {
  DeleteFacility,
  DeleteFacilityResponse
} from "@/api//facility/DeleteFacility"
import { useAuth } from "@/context/AuthContext"
import { Loader2 } from "lucide-react"

export interface FacilitiesTabContentProps {
  facilitiesArray: Facility[]
  refetch?: () => void
}

export const FacilitiesTabContent = ({
  facilitiesArray,
  refetch
}: FacilitiesTabContentProps) => {
  const { accessToken } = useAuth()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [facilityToDelete, setFacilityToDelete] = useState<
    Facility | undefined
  >(undefined)

  const deleteFacility = useCallback(
    (id: string) =>
      DeleteFacility(accessToken, id).then((result) => {
        refetch?.()
        return result
      }),
    [facilityToDelete]
  )

  return (
    <Card>
      <CardHeader>
        <CardTitle>Your Facilities</CardTitle>
        <CardDescription>
          Manage your facilities and view their sales performance.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="hidden w-[100px] sm:table-cell">
                <span className="sr-only">Image</span>
              </TableHead>
              <TableHead>Facility</TableHead>
              <TableHead>Address</TableHead>
              <TableHead>Status</TableHead>
              <TableHead className="hidden md:table-cell">
                Total Zones
              </TableHead>
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {facilitiesArray.map((facility) => (
              <FacilitiesTableRow
                key={facility.id}
                facility={facility}
                onDelete={(facility) => {
                  setFacilityToDelete(facility)
                  setDeleteDialogOpen(true)
                }}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      {facilityToDelete && (
        <DeleteDialog
          open={deleteDialogOpen}
          facility={facilityToDelete}
          onSubmit={deleteFacility}
          onOpenChange={setDeleteDialogOpen}
        />
      )}
    </Card>
  )
}

interface DeleteDialogProps {
  facility: Facility
  open: boolean
  onOpenChange?: (open: boolean) => void
  onSubmit?: (id: string) => Promise<DeleteFacilityResponse>
}

const DeleteDialog = ({
  open,
  onOpenChange,
  onSubmit,
  facility
}: DeleteDialogProps) => {
  const [deleteIsPending, setDeleteIsPending] = useState(false)
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="px-10">
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to delete this facility?
          </DialogTitle>
        </DialogHeader>
        <div className="my-10 grid grid-cols-1 gap-6">
          <DialogDataRow label="Name" content={facility.name} />
          {facility.address && (
            <DialogDataRow label="Address">
              <AddressRow
                className="text-right justify-end"
                address={facility.address}
              />
            </DialogDataRow>
          )}
          <DialogDataRow label="Status" content={facility.status} />
          <DialogDataRow
            label="Total Lots"
            content={
              (facility?.unassignedZone?.length ?? 0) +
              (facility?.assignedZone?.length ?? 0)
            }
          />
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange?.(false)}>
            Cancel
          </Button>
          <Button
            disabled={deleteIsPending}
            variant="destructive"
            onClick={() => {
              setDeleteIsPending(true)
              onSubmit?.(facility.id)
                .then(() => {
                  setDeleteIsPending(false)
                  onOpenChange?.(false)
                })
                .catch(() => setDeleteIsPending(false))
            }}>
            {deleteIsPending && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

interface DialogDataRowProps {
  label: string
  content?: string | number
}

const DialogDataRow = ({
  label,
  content,
  children
}: React.PropsWithChildren<DialogDataRowProps>) => {
  return (
    <div className="grid grid-cols-[auto_auto] gap-3 items-center">
      <Label className="font-bold">{label}</Label>
      {content && <p className="text-right">{content}</p>}
      {children}
    </div>
  )
}
