import { Link } from "react-router-dom"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import CancelReservationDialog from "./CancelReservationDialog"
import { TypographyH4 } from "../ui/typography/h4"
import { OrderSummary } from "@/components/custom/OrderSummary"
import { getFormattedDateTime } from "@/lib/utils"

const ReservationCard = ({ reservationData, cancellable, tippable, onCancellation }) => {
  if (!reservationData) return null

  const {
    id,
    checkinDatetime,
    checkoutDatetime,
    order,
    facility,
    vehicle = { make: "", model: "", color: "", licenseNumber: "" },
    spot,
    lot,
    images,
    tipLink
  } = reservationData

  const [formattedCheckinTime, formattedCheckinDate] = getFormattedDateTime(checkinDatetime)
  const [formattedCheckoutTime, formattedCheckoutDate] = getFormattedDateTime(checkoutDatetime)

  const getThumbnailImage = (images) => {
    return images.find((image) => image.isThumbnail)
  }

  return (
    <div>
      <Card className="sm:col-span-2 mb-2">
        <CardHeader className="pb-3 flex lg:flex-row justify-between m-0">
          <CardTitle>{spot?.name || lot?.name}</CardTitle>
        </CardHeader>
        <CardContent className="p-2 lg:p-6">
          <div>
            <div className="lg:grid grid-cols-2 gap-8">
              <div>
                <img
                  alt={getThumbnailImage(images)?.name || images[0]?.name}
                  className="aspect-square rounded-md object-cover m-auto lg:h-96 h-52 w-full"
                  src={getThumbnailImage(images)?.file || images[0]?.file}
                />
              </div>
              <div>
                <p className="font-semibold mt-4 lg:mt-0">{facility?.name}</p>
                <address className="font-medium not-italic tracking-wide">
                  <p>{facility?.address?.address1}</p>
                  <p>{facility?.address?.address2}</p>
                  <p>
                    {facility?.address?.city}, {facility?.address?.state} {facility?.address?.zipCode}
                  </p>
                </address>
                <div className="bg-gray-100 px-2 py-1 mt-1 rounded">
                  <p className="font-semibold text-center lg:text-left">Order Summary</p>
                  <OrderSummary items={order?.itemsMetadata} amount={order?.finalAmount} />
                </div>
                <div className="flex py-2">
                  <div className="flex flex-col">
                    <TypographyH4 className="text-md">Check In:</TypographyH4>
                    <p>{formattedCheckinDate} {formattedCheckinTime}</p>
                  </div>
                  <div className="flex flex-col ml-auto">
                    <TypographyH4 className="text-md">Check Out:</TypographyH4>
                    <p>{formattedCheckoutDate} {formattedCheckoutTime}</p>
                  </div>
                </div>
                {vehicle && (
                  <div className="py-2">
                    <p className="font-semibold">Vehicle: </p>
                    <p>
                      <span className="capitalize">{vehicle.color.toLowerCase()}</span>{" "}
                      {vehicle.make} {vehicle.model} {vehicle.licenseNumber}
                    </p>
                  </div>
                )}
                {
                  lot?.parkerInstructions && (
                    <div className="bg-gray-100 p-2 mt-1">
                      <TypographyH4 className="text-md">Parking Instructions:</TypographyH4>
                      <p>{lot?.parkerInstructions}</p>
                    </div>
                  )
                }
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 justify-between items-center mt-2">
                  {
                    (tipLink && tippable) && (
                      <Link className="w-full" to={tipLink}>
                        <Button className="w-full bg-green-300 hover:bg-green-500 text-primary flex gap-2">$ Tip Valet</Button>
                      </Link>
                    )
                  }
                  {
                    cancellable && (
                      <CancelReservationDialog id={id} onCancellation={onCancellation} />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default ReservationCard
