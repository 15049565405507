import { deepMapKeys } from "@/lib/deepMapKeys"
import { buildRequest } from "."
import { handleError, clearError } from '@/lib/errorHandler'
import { api, clearTokens } from '@/api/const'

// To override the default error message, pass a customError string to the function
export const postData = async (uri, payload, accessToken, dropTrailingSlash = false, useImageHeader = false, customError) => {
  const { url, headers, serializedPayload } = buildRequest(
    uri,
    accessToken,
    payload,
    dropTrailingSlash,
    useImageHeader
  )

  try {
    clearError()
    const result = await api.post(url, serializedPayload, {
      headers
    })

    if (result.data && result.data.length) {
      return result.data.map((item) => deepMapKeys(item))
    } else {
      return result.data && deepMapKeys(result.data)
    }
  } catch (error) {
    const { detail } = error.response.data

    handleError({
      error: customError || detail || error.message
    })
    throw error
  }
}

export const getRefreshAccessToken = async (accessToken, refreshToken) => {
  try {
    const result = await api.post('login/refresh/', { refresh: refreshToken });
    return result.data.access;
  } catch (error) {
    console.error('Refresh token is invalid or expired');
    clearTokens();
    return new Promise(() => { }); // This prevents further execution
  }
}

export const makeLoginCall = async (formData) => {
  return await postData("login", formData)
}

export const makeLogoutCall = async (refreshToken) => {
  return await postData("logout", { refresh: refreshToken })
}

export const register = async (formData) => {
  return await postData("register", formData)
}

export const addVehicle = async (formData, accessToken) => {
  return await postData("v1/parker/vehicle", formData, accessToken)
}

export const addMember = async (formData, accessToken) => {
  return await postData("v1/owner/team", formData, accessToken)
}

export const makeReservation = async (formData) => {
  return await postData("v1/reservations", formData)
}

export const resetPassword = async (formData) => {
  return await postData("reset_password", formData)
}

export const completeResetPassword = async (formData, token) => {
  const path = `reset_password/complete?token=${token}`
  return await postData(path, formData, undefined, true)
}

export const holdReservation = async (payload) => {
  return await postData("v1/locks", payload)
}

export const releaseReservationHold = async (payload) => {
  return await postData("v1/locks/release", payload)
}

export const validateCoupon = async (payload) => {
  return await postData("v1/coupons/validate", payload)
}

export const cancelReservation = async (id, payload) => {
  return await postData(`v1/reservations/${id}/cancel`, payload)
}

export const addFacility = async (payload) => {
  return await postData("v1/facilities", payload)
}

export const ownerInterest = async (payload) => {
  return await postData("v1/ppointerest", payload)
}

export const buyProduct = async (payload) => {
  return await postData("v1/products/buy", payload)
}

export const uploadMedia = async (id, payload, accessToken) => {
  return await postData(
    `v1/facilities/${id}/upload`,
    payload,
    accessToken,
    false,
    true
  )
}

/**
 * Subscribe a user to email notifications
 * @param {Object} payload
 * @param {string} payload.email - User's email address
 * @param {string} [payload.subscribed_via_link] - Optional subscription source identifier
 * @returns {Promise<any>}
 */
export const emailSignup = async (payload) => {
  console.log("payload", payload)
  return await postData("v1/subscribe", payload)
}
