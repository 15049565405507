import { TableDrawerWrapper } from "@/components/custom/TableDrawerWrapper"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { TypographyH4 } from "@/components/ui/typography/h4"
import { Plus } from "lucide-react"
import { Link } from "react-router-dom"
import { ParkingLotSection } from "./components/ParkingLotSection"
import {
  ParkingSpotSection,
  ParkingSpotSectionSkeleton
} from "./components/ParkingSpotSection"
import { Spot } from "@/api/types/internal/Spot"

export interface ZoneDrawerProps {
  facilityId: string
  unassignedZones?: UnassignedZone[]
  spots?: Spot[]
}

export const FacilityDrawer = ({
  facilityId,
  unassignedZones,
  spots
}: ZoneDrawerProps) => {
  return (
    <TableDrawerWrapper>
      <div className="mx-3 my-6 flex flex-col gap-3">
        <TypographyH4 className="text-lg font-bold">
          Unassigned Lots
        </TypographyH4>
        {unassignedZones ?
          unassignedZones.map((zone) => (
            <ParkingLotSection key={zone.id} zone={zone} />
          ))
        : <ParkingSpotSectionSkeleton />}
        <Button size="sm" className="my-3 h-8 gap-1 w-fit" asChild>
          <Link to={`/owner-dashboard/facilities/${facilityId}/lots/new`}>
            <Plus className="mr-1 h-4 w-4" /> New Parking Lot
          </Link>
        </Button>
      </div>
      <Separator />
      <div className="m-3 my-6 flex flex-col gap-3">
        <TypographyH4 className="text-lg font-bold">Spots</TypographyH4>
        {spots ?
          spots?.map((spot) => <ParkingSpotSection key={spot.id} spot={spot} />)
        : <ParkingSpotSectionSkeleton />}
        <Button size="sm" className="my-3 h-8 gap-1 w-fit w-content" asChild>
          <Link to={`/owner-dashboard/facilities/${facilityId}/spots/new`}>
            <Plus className="mr-1 h-4 w-4" /> New Parking Spot
          </Link>
        </Button>
      </div>
    </TableDrawerWrapper>
  )
}

export const AddAssignedZoneButton = () => {
  return <Button asChild>"</Button>
}
