import { z } from "zod"

import { USStates, vehicleType } from "@/constants/constants"
import { PhoneNumberSchema } from "@/constants/PhoneSchema"

export const requiredString = z.string().trim().min(1, {
  message: "Required"
})

export const requiredEmail = z
  .string()
  .trim()
  .email({ message: "Invalid email address" })

export const vehicleFormSchema = z.object({
  make: requiredString,
  model: requiredString,
  type: z.nativeEnum(vehicleType),
  color: requiredString,
  licenseNumber: z
    .string()
    .trim()
    .min(1, {
      message: "Required"
    })
    .max(8, { message: "License plate number must be 8 characters or less" }),
  registeredState: z.nativeEnum(Object.keys(USStates)),
  isEv: z.boolean().default(false).optional()
})

export const vehicleDefaultValues = {
  make: "",
  model: "",
  type: vehicleType.SEDAN,
  licenseNumber: "",
  registeredState: USStates.CO.abbreviation,
  color: "",
  isEv: false
}

export const contactSchema = z.object({
  email: requiredEmail,
  phone: PhoneNumberSchema
})

const passwordRegex = new RegExp(
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
)

export const requiredPassword = z
  .string()
  .trim()
  .min(8, { message: "Password must be at least 8 characters" })
  .max(20, { message: "Password must be no more than 20 characters" })
  .regex(passwordRegex, {
    message:
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
  })

export const couponCodeSchema = z.object({
  code: z.string().optional()
})
