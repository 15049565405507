import { useRef, useState } from "react"
import { useSearchFlow } from "@/hooks/useSearchFlow"
import { useBreakpoint } from "@/hooks/useBreakpoint"
import MobileParkSearchForm from "@/components/custom/MobileParkSearchForm"
import ParkSearchForm from "@/components/custom/ParkSearchForm"
import ParkSearchResult from "@/components/custom/ParkSearchResult"
import ParkSearchResults from "@/components/custom/ParkSearchResults"
import ParkingSpotsMap from "@/components/custom/ParkingSpotsMap"
import Loading from "@/components/custom/Loading"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel"
import { CarouselSearchResults } from "@/pages/Search/CarouselSearchResults"
import { Badge } from "@/components/ui/badge"
import { cn } from "@/lib/utils"
import InfoWindowContent from "@/components/custom/InfoWindowContent"
import { DialogTrigger } from "@radix-ui/react-dialog"
import { Button } from "@/components/ui/button"
import FacilityDetailsDialog from "@/components/custom/FacilityDetailsDialog"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { Car } from "lucide-react"
import { format } from "date-fns"

const SearchPage = () => {
  const {
    facilities,
    facilitiesLoading,
    handleSearchQuery,
    searchQuery,
    searchLoading,
    searchSuccess,
    searchResults,
    markers,
    reserveSpot
  } = useSearchFlow()

  const availableResults = searchResults?.filter((result) => result.isAvailable)
  const unavailableResults = searchResults?.filter((result) => !result.isAvailable)
  const availableMarkers = markers?.filter((marker) => marker.isAvailable)
  const sortedResults = [...(availableResults || []), ...(unavailableResults || [])]

  const [activeCardIndex, setActiveCardIndex] = useState(null)
  const [api, setApi] = useState(null)
  const [carouselHasBeenSelected, setCarouselHasBeenSelected] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const carouselWrapper = useRef(null)

  const desktopBreakpoints = ["lg", "xl", "2xl"]
  const breakpoint = useBreakpoint()
  const isDesktop = desktopBreakpoints.includes(breakpoint)

  const scrollToCarousel = (facilityId, index) => {
    if (index !== -1 && api) {
      api.scrollTo(index)
      setActiveCardIndex(index)
    }

    if (facilityId === null) {
      api.scrollTo(0)
      setActiveCardIndex(null)
    }
  }

  const onSelect = (api) => {
    if (!api) return

    api.on('select', () => {
      if (!carouselHasBeenSelected) {
        setCarouselHasBeenSelected(true)
      }
      setActiveCardIndex(api.selectedScrollSnap())
    })
  }

  const resetCarouselSelection = () => {
    setCarouselHasBeenSelected(false)
    scrollToCarousel(null)
  }
 
  const renderSearchResults = () => {
    if (searchLoading) return <Loading className="h-full" />
    if (searchSuccess && searchResults.length > 0) {
      return (
        <Carousel
          opts={{ align: "start", loop: true }}
          setApi={setApi}
          onSelectProp={onSelect}
          className={cn("mx-auto", {
            "w-3/4": availableResults.length > 1,
            "w-full": availableResults.length === 1
          })}
        >
          <CarouselContent
            showOverflow={true}
            className={cn('ml-0 mr-2 block', {
              "-ml-4 flex": availableResults.length > 1,
            })}
          >
            <CarouselSearchResults
              searchResults={searchResults}
              setDialogOpen={setDialogOpen}
              scrollToCarousel={scrollToCarousel}
            />
          </CarouselContent>
        </Carousel>
      )
    }
    if (searchResults && searchResults.length < 1) {
      return (
        <Card className="w-1/2 mx-auto">
          <CardContent className="p-4">
            This property is fully booked for the date you have chosen.
            Please try another date or an alternate property.
          </CardContent>
        </Card>
      )
    }
    return null
  }

  if (facilitiesLoading) {
    return <Loading />
  }

  if (isDesktop) {
    return (
      <div className="h-[calc(100vh-64px)] px-2 py-6 grid grid-cols-1 md:px-6 lg:grid-cols-6 gap-6">
        <Card className="col-span-2 flex flex-col gap-4 overflow-y-scroll lg:shadow-xl">
          <CardHeader>
            <ParkSearchForm
              searchQuery={searchQuery}
              handleSearchQuery={handleSearchQuery}
              facilities={facilities}
            />
          </CardHeader>
          <CardContent>
            {searchLoading && <Loading className="h-full" />}
            {searchSuccess && (
              <ParkSearchResults
                searchResults={availableResults}
                availableResults={availableResults}
                unavailableResults={unavailableResults}
                reserveSpot={reserveSpot}
              />
            )}
            {
              searchResults && searchResults.length < 1 && (
                <div className="rounded-lg border bg-card text-card-foreground col-span-2 flex flex-col gap-4 overflow-y-scroll p-4">
                  This property is fully booked for the date you have chosen.
                  Please try another date or an alternate property.
                </div>
              )
            }
          </CardContent>
        </Card>
        <div className="col-span-4">
          <div className="w-full h-full rounded-xl border overflow-hidden lg:shadow-xl">
            <ParkingSpotsMap markers={availableMarkers} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col relative h-[calc(100vh-64px)]">
      {
        isDesktop && (
          <div className="h-[calc(100vh-64px)] flex flex-col">
            <div className="p-4">
              <ParkSearchForm
                searchQuery={searchQuery}
                handleSearchQuery={handleSearchQuery}
                facilities={facilities}
              />
            </div>
          </div>
        ) 
      }
      {
        !isDesktop && (
          <div className="flex absolute top-0 z-10 w-full">
            {
              markers && markers.length > 0 && (
                <FacilityDetailsDialog
                  data={sortedResults[activeCardIndex]}
                  dialogOpen={dialogOpen}
                  closeDialog={() => setDialogOpen(false)}
                />
              )
            }
            <MobileParkSearchForm
              searchQuery={searchQuery}
              handleSearchQuery={handleSearchQuery}
              facilities={facilities}
              resetCarouselSelection={resetCarouselSelection}
            />
          </div>
        )
      }
      <div className="flex-grow relative">
        <ParkingSpotsMap markers={availableMarkers} scrollToCarousel={scrollToCarousel} activeCardIndex={activeCardIndex} />
      </div>
      <div ref={carouselWrapper} className="fixed bottom-0 left-0 right-0 py-4 overflow-x-hidden results-wrapper">
        {renderSearchResults()}
      </div>
    </div>
  )
}

export default SearchPage
