import { useState } from "react"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "@/components/ui/alert-dialog"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { cancelReservation } from "@/api/post"
import { useCustomAlert } from "@/hooks/useCustomAlert"

const CancelReservationDialog = ({ id, onCancellation }) => {
  const [reason, setReason] = useState("")
  const [step, setStep] = useState(1)
  const [open, setOpen] = useState(false)
  const [cancellationOptions, setCancellationOptions] = useState("")
  const [cancellationMessage, setCancellationMessage] = useState("")
  const { showMessage } = useCustomAlert()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    type: ''
  })

  const handleClose = () => setOpen(false)
  const handleRadioChange = (value) => {
    setFormData(prev => ({ ...prev, type: value }))
  }

  const handleNext = () => {
    if (step === 1) {
      setStep(2)
    }
  }

  const handlePrevious = () => {
    if (step === 2) {
      setStep(1)
    }
  }

  const handleSubmit = async () => {
    try {
      const data = await cancelReservation(id, {
        cancellationOption: formData.type,
        proceedForCancellation: true
      })
      if (data.reservationStatus === "CANCELLED") {
        showMessage(data.detail, "success")
        onCancellation?.(id)
      }
    } catch (error) {
      showMessage(data.detail, "error")
    } finally {
      setOpen(false)
    }
  }

  const getCancellationOptions = async () => {
    const { cancellationOptions, message } = await cancelReservation(id)
    const recommendedOption = cancellationOptions.find((option) => option.isRecommended)

    setFormData({ type: recommendedOption.type })
    setCancellationOptions(cancellationOptions)
    setCancellationMessage(message)

    setStep(2)
  }

  const termsAndConditionsLink = "https://spotsurfer.com/terms-and-conditions#:~:text=Cancellations%20and%20Refunds"

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="destructive">Cancel Reservation</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          {
            step === 1 && (
              <>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  Please review our
                  <a href={termsAndConditionsLink} target="__BLANK" className="underline px-1">cancellation policy</a>
                  before continuing.
                </AlertDialogDescription>
              </>
            )
          }
          {(cancellationMessage && step === 2) && cancellationMessage}
        </AlertDialogHeader>
          <form onSubmit={handleSubmit}>
            {step === 2 && (
              <div className="space-y-4">
                <Label id="cancellation-refund-option">Select Your Refund Option</Label>
                <RadioGroup aria-labelledby="cancellation-refund-option" value={formData.type} onValueChange={handleRadioChange}>
                  {(cancellationOptions && cancellationOptions.length > 0) && cancellationOptions.map((option) => (
                    <div className="flex items-center space-x-2" key={option.type}>
                      <RadioGroupItem value={option.type} id={option.type} />
                      <Label htmlFor={option.type}>{option.description}</Label>
                    </div>
                  ))}
                </RadioGroup>
              </div>
            )}
          </form>
        <AlertDialogFooter>
          <div className="flex flex-wrap justify-between gap-2">
            {step === 1 && (
              <Button variant="outline" className="flex-1" onClick={handleClose}>Close</Button>
            )}
            {step === 2 && (
              <Button variant="outline" onClick={handlePrevious}>
                Previous
              </Button>
            )}
            {step === 1 ? (
              <Button className="flex-1" variant="destructive" onClick={getCancellationOptions}>Continue Cancellation</Button>
            ) : (
              <Button variant="destructive" onClick={handleSubmit}>Submit</Button>
            )}
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default CancelReservationDialog
