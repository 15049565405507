import { AddressBlock } from "@/components/custom/Address/AddressBlock"
import { useAuth } from "@/context/AuthContext"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table"
import { Mail, Phone } from "lucide-react"
import { format } from "date-fns"
import type { Reservation } from '@/api/types/internal/Reservation'

export interface ReservationTableProps {
  reservations: Reservation[]
  onReservationClicked?: (reservation: Reservation) => void
}

export const ReservationTable = ({ reservations, onReservationClicked }: ReservationTableProps) => {
  const { user } = useAuth()

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="text-black">Customer</TableHead>
          <TableHead className="text-black">Facility</TableHead>
          {/* <TableHead className="hidden sm:table-cell">Status</TableHead> */}
          <TableHead className="hidden md:table-cell text-black">Reservation Date</TableHead>
          <TableHead className="hidden sm:table-cell text-black">License Plate #</TableHead>
          <TableHead className="hidden sm:table-cell text-black">Add-ons</TableHead>
          {
            (user.role === "CPO" || user.role === "PPO") && (
              <TableHead className="text-right text-black">Amount</TableHead>
            )
          }
        </TableRow>
      </TableHeader>
      <TableBody>
        {reservations.map((reservation, i) => {
          return (
          <ReservationRow
            onClick={() => onReservationClicked?.(reservation)}
            reservation={reservation}
            key={i}
          />
        )})}
      </TableBody>
    </Table>
  )
}

export interface ReservationRowProps {
  reservation: Reservation
  onClick: () => void
}

export const ReservationRow = ({ reservation, onClick }: ReservationRowProps) => {
  const { user } = useAuth()

  return (
    <TableRow onClick={onClick} key={reservation.id} className="cursor-pointer even:bg-gray-100 hover:bg-gray-200">
      <TableCell className="grid gap-1 border-gray-300">
        <div className="font-medium">
          {reservation?.parker?.user?.firstName}{" "}
          {reservation?.parker?.user?.lastName}
        </div>
        <div className="text-sm md:flex items-center">
          <Mail className="h-4 w-4 inline mr-2" />
          <span>{reservation?.parker?.email}</span>
        </div>
        {reservation?.parker?.user?.phone && (
          <div className="text-sm flex items-center">
            <Phone className="h-4 w-4 inline mr-2" />
            <span>{reservation.parker.user.phone}</span>
          </div>
        )}
      </TableCell>
      <TableCell className="sm:table-cell grid gap-2">
        {reservation?.facility?.name}
      </TableCell>
      {/* <TableCell className="hidden sm:table-cell">
        <Badge className="text-xs capitalize" variant={badgeVariant[status]}>
          {reservation.status.toLowerCase()}
        </Badge>
      </TableCell> */}
      <TableCell className="hidden md:table-cell">
        <span className="flex flex-col">
          {/* <small>Check in:</small> */}
          <small className="font-semibold bg-green-200 rounded px-1 mb-1">{format(reservation?.checkinDatetime, "PP")}</small>
        </span>
        <span className="flex flex-col">
          {/* <small>Check out:</small> */}
          <small className="font-semibold bg-red-200 rounded px-1">{format(reservation?.checkoutDatetime, "PP")}</small>
        </span>
      </TableCell>
      <TableCell className="hidden sm:table-cell">
        {reservation?.vehicle?.licenseNumber || 'N/A'}
      </TableCell>
      <TableCell className="hidden sm:table-cell relative">
        {reservation?.paidAmenities[0]?.name}
        {
          reservation?.paidAmenities.length > 1 && (
            <span className="text-xs ml-1 p-1 font-semibold">+{reservation?.paidAmenities.length - 1}</span>
          )
        }
      </TableCell>
      {
        (user?.role === "CPO" || user?.role === "PPO") && (
          <TableCell className="text-right">
            ${reservation?.order?.finalAmount}
          </TableCell>
        )
      }
    </TableRow>
  )
}
