import spotSurferIcon from "@/assets/pngs/spotsurferLogoNamed.png"
import { Helmet } from "react-helmet-async"

const defaultProps = {
  title: "Reserve Your Parking Spot in Vail & Beaver Creek",
  description: "Skip the parking hassle! Stress-free parking is just a tap away.",
  url: "https://spotsurfer.com",
  image: spotSurferIcon,
  imageAlt: "Spotsurfer Logo"
}

interface MetaHeadProps {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
  imageAlt?: string;
}

export const MetaHead = ({
  title = defaultProps.title,
  description = defaultProps.description,
  url = defaultProps.url,
  image = defaultProps.image,
  imageAlt = defaultProps.imageAlt
}: MetaHeadProps): React.ReactElement => {
  return (
    <Helmet>
      {/* <!-- HTML Meta Tags --> */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content="Spotsurfer" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={imageAlt} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="spotsurfer.com" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}