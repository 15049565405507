import SimpleDialog from "@/components/custom/SimpleDialog"
import TeamForm from "@/components/custom/TeamForm"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription
} from "@/components/ui/card"
import { CircleCheck, CircleX } from 'lucide-react';
import { Separator } from "@/components/ui/separator"
import { useState } from "react"
import { blackButtonStyles, cn } from "@/lib/utils"

const TeamMember = ({ member }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const {
    id,
    email,
    firstName,
    lastName,
    role,
    phone,
    optoutOfSms,
    facilities: facilitiesData
  } = member

  const facilities = facilitiesData.map(({ name: facilityName }, i) => (
    <div className="flex flex-col text-sm" key={i}>
      <p className="mb-1"> Facility Name</p>
      <p className="font-semibold">{facilityName}</p>
    </div>
  ))

  return (
    <Card key={id}>
      <CardHeader>
        <CardTitle className="text-lg font-semibold">
          {firstName} {lastName}
        </CardTitle>
        <CardDescription>
          <span className="font-semibold">{role}</span>
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-2">
        <div className="flex flex-col text-sm">
          <p className="mb-1">Email</p>
          <p className="font-semibold">{email}</p>
        </div>
        <div className="flex flex-col text-sm">
          <p className="mb-1">Phone</p>
          <p className="font-semibold">{phone}</p>
        </div>
        <div className="flex flex-col text-sm">
          <p className="flex items-center mb-1 justify-between">Text Notifications
            {
              optoutOfSms ? (
                <CircleX className="stroke-red-500" />
              ): (
                <CircleCheck className="stroke-green-500" />
              )
            }
          </p>
        </div>
        <Separator />
        <p className="font-semibold">Associated Facilities</p>
        <div className="grid md:grid-cols-2 gap-2">{facilities}</div>
      </CardContent>
      <CardFooter className="grid gap-2">
        <SimpleDialog
          dialogTrigger={
            <span className={cn(blackButtonStyles(), "block")}>
              Edit
            </span>
          }
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}>
          <TeamForm
            actionType="EDIT"
            preExistingValues={member}
            setIsDialogOpen={setIsDialogOpen}
          />
        </SimpleDialog>
      </CardFooter>
    </Card>
  )
}

export default TeamMember
