import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card"
import TypographyH1 from "@/components/ui/typography/h1"
import { TypographyH2 } from "@/components/ui/typography/h2"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/components/ui/carousel"
import { Fragment, useCallback, useState } from "react"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from "@/components/ui/hover-card"
import { Separator } from "@/components/ui/separator"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { Button } from "@/components/ui/button"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  GetSeasonPassesAndParkingPacks,
  GetSeasonPassesAndParkingPacksResponse,
  GetSeasonPassesKey
} from "@/api/parking-packs/GetSeasonPassesAndParkingPacks"
import { SeasonPacksSkeleton } from "./Skeleton"
import { ComingSoon } from "./ComingSoon"
import { useAuth } from "@/context/AuthContext"
import Icon from "@/components/custom/Icon"
import { cn } from "@/lib/utils"
import { LoginDialog } from "@/components/custom/LoginDialog/LoginDialog"
import { BuyParkingPackOrSeasonPass } from "@/api/parking-packs/BuyParkingPackOrSeasonPass"
import { useMedia } from "react-use"
import { NotepadText } from "lucide-react"
import { GenericDialog } from "@/components/custom/GenericDialog"
import spotSurferBucks from "@/assets/pngs/spotsurferbucks.png"
import { getTokens } from "@/api/const"

const greenButton = "grow inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 cursor-pointer bg-green-700  text-white hover:text-white hover:bg-green-800 h-10 px-4 py-2 border-none"

interface BuyMutationVariables {
  id: string;
  productType: string;
}

type PackCarouselProps = {
  children: React.ReactNode;
};

export const SeasonPacks = () => {
  const { accessToken } = useAuth()
  const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false)
  const [idToPurchaseAfterLogin, setIdToPurchaseAfterLogin] = useState("")
  const [activeProductType, setActiveProductType] = useState("")
  const { data, isPending, isError } =
    useQuery<GetSeasonPassesAndParkingPacksResponse>({
      queryKey: [GetSeasonPassesKey],
      queryFn: () => GetSeasonPassesAndParkingPacks(accessToken)
    })

  const buyMutation = useMutation({
    mutationKey: ["buy-season-pass"],
    mutationFn: ({ id, productType }: BuyMutationVariables) => BuyParkingPackOrSeasonPass({ id, productType }, accessToken),
    onSuccess: ({ reservation, isError, error }) => {
      if (isError) {
        console.error(error)
      } else if (reservation?.checkoutUrl) {
        window.location.replace(reservation?.checkoutUrl)
      } else {
        console.error("An error has occured")
      }
    },
    onError: (error) => {
      console.error(error)
    }
  })

  const submitHandler = useCallback(
    (id: string, productType?: string | any) => {
      if (getTokens()?.accessToken) {
        buyMutation.mutate({ id, productType })
      } else {
        setIdToPurchaseAfterLogin(id)
        setActiveProductType(productType)
        setLoginDialogIsOpen(true)
      }
    },
    [buyMutation]
  )

  const dialogSubmitHandler = useCallback(() => {
    submitHandler(idToPurchaseAfterLogin, activeProductType)
  }, [submitHandler, idToPurchaseAfterLogin, activeProductType])

  return (
    <div className="flex flex-col gap-6 p-2 lg:py-10 mx-auto max-w-7xl">
      <div className="flex flex-col">
        <TypographyH1>
          Spotsurfer Discount Parking and Season Passes
        </TypographyH1>
        <p>
          Unlock Convenience, Save Money, and Elevate Your Resort Experience.
        </p>
      </div>
      <div className="flex flex-col gap-12">
        <CreditPackRow creditPacks={data?.data?.spotsurfer?.creditPacks} submitHandler={submitHandler} />
      </div>
      <div className="flex flex-col gap-12">
        {isPending ?
          <>
            <SeasonPacksSkeleton />
            <SeasonPacksSkeleton />
            <SeasonPacksSkeleton />
          </>
        : isError || data.isError ?
          <ComingSoon />
        : data?.data?.facilities
            ?.filter(
              (facility) =>
                facility.parkingPacks?.length > 0 ||
                facility.seasonPasses?.length > 0
            )
            .map((facility, i) => (
              <FacilityRow submitHandler={submitHandler} facility={facility} key={`facility-row-${i}`} />
            ))
        }
      </div>
      <LoginDialog
        open={loginDialogIsOpen}
        onOpenChange={setLoginDialogIsOpen}
        onSuccess={dialogSubmitHandler}
      />
    </div>
  )
}

const FacilityRow = ({ facility, submitHandler }: any) => {
  return (
    <Card className="shadow-xl p-6">
      <div className="flex flex-col gap-6 md:grid md:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_3fr]">
        <div className="flex gap-6">
          <FacilityLabel facility={facility} />
          <Separator orientation="vertical" />
        </div>
        <PackCarousel>
          {facility.parkingPacks?.map((pack: any) => (
            <CarouselItem key={pack.id} className="pl-4 sm:basis-1/2 md:basis-1/3">
              <ParkingPackCard submitHandler={submitHandler} pack={pack} productType={'PARKING_PACK'} />
            </CarouselItem>
          ))}
          {facility.seasonPasses?.map((pack: any) => (
            <CarouselItem key={pack.id} className="pl-4 sm:basis-1/2 md:basis-1/3">
              <ParkingPackCard submitHandler={submitHandler} pack={pack} key={pack.id} productType={'SEASON_PASS'} />
            </CarouselItem>
          ))}
        </PackCarousel>
      </div>
    </Card>
  )
}

const PackCarousel = ({ children }: PackCarouselProps) => {
  return (
    <div className="flex flex-col gap-6 w-full overflow-hidden justify-center">
      <Carousel
        opts={{
          align: "start",
          loop: true,
          slidesToScroll: 1,
          containScroll: "trimSnaps"
        }}
        className="w-full"
      >
        <CarouselContent className="w-full relative -ml-2 mr-1 p-2">
          {children}
        </CarouselContent>
        <CarouselPrevious className="left-0" />
        <CarouselNext className="right-0" />
      </Carousel>
    </div>
  )
}

const CreditPackRow = ({ creditPacks, submitHandler }: any) => {
  return (
    <Card className="shadow-xl p-6">
      <div className="flex flex-col gap-6 md:grid md:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_3fr]">
        <div className="flex gap-6">
          <div className="flex flex-col gap-3">
            <TypographyH2 className="font-semibold text-lg lg:text-xl">
              Spotsurfer Bucks
            </TypographyH2>
            <img className="max-h-48 rounded-xl square mx-auto" src={spotSurferBucks} />
            {/* <p>Spotsurfer Bucks are a redeemable currency for the SpotSurfer platform. Redeem at checkout for your next reservation.</p> */}
          </div>
          <Separator orientation="vertical" />
        </div>
        <PackCarousel>
          {/* TODO: Extract */}
          {creditPacks?.map((pack: any) => (
            <CarouselItem key={pack.id} className="pl-4 sm:basis-1/2 md:basis-1/3">
              <ParkingPackCard
                pack={pack}
                submitHandler={submitHandler}
                hideImage={true}
                productType={'CREDIT_PACK'}
              />
            </CarouselItem>
          ))}
        </PackCarousel>
      </div>
    </Card>
  );
};

export const ParkingPackCard = ({ pack, submitHandler, productType, hideImage }: any) => {
  const shouldShowImage = pack.file && !hideImage
  const formattedPackName = `${pack.name} for $${pack.price}`

  return (
    <Card className="shadow-xl flex flex-col min-h-[300px] justify-between" key={pack.id}>
      <CardHeader className="relative flex flex-col gap-6">
        <div
          className={`grid grid-cols-[${shouldShowImage ? "1fr_2fr" : "1fr"}] gap-3`}>
          {shouldShowImage && (
            <img
              className="rounded-xl max-h-24 max-w-24 square"
              src={pack.file}
            />
          )}
          <div className="flex flex-col gap-1">
            <TypographyH3
              className={cn(
                "text-md lg:text-lg font-semibold",
                pack.iconName && "mr-8"
              )}>
              {formattedPackName}
            </TypographyH3>
          </div>
        </div>
        {pack.iconName && (
          <Icon
            className="h-6 w-6 absolute top-3 right-3"
            name={pack.iconName}
          />
        )}
      </CardHeader>
      <CardContent className="grow">
        <p className="overflow-hidden text-ellipsis line-clamp-3">{pack.description}</p>
      </CardContent>
      <CardFooter className="flex gap-1">
      <Button
          onClick={() => submitHandler?.(pack.id, productType)}
          variant="outline"
          className={greenButton}>
          Buy Now
        </Button>
      </CardFooter>
    </Card>
  )
}

const FacilityLabel = ({ facility }: any) => {
  const isMobile = useMedia(`(max-width: 768px)`)

  return (
    <div className="flex flex-col gap-3 min-w-[272.5px]">
      <TypographyH2 className="font-semibold text-lg lg:text-xl">
        {facility.name}
      </TypographyH2>
      {facility?.images?.length && facility?.images?.length > 0 && (
        <Carousel
          opts={{ align: "start", loop: true }}
          className="mx-auto w-full lg:max-w-100">
          <CarouselContent key={facility.id}>
            {facility?.images?.map((image: Image, i: number) => (
              <Fragment key={`frag-car-${i}`}>
                <CarouselItem key={image.file}>
                  <img
                    src={image.file}
                    className="max-h-48 w-full object-cover rounded-xl"
                  />
                </CarouselItem>
              </Fragment>
            ))}
          </CarouselContent>
          <CarouselPrevious className="left-4" />
          <CarouselNext className="right-4" />
        </Carousel>
      )}
      {facility?.description ?
        isMobile ?
          <HoverCard>
            <HoverCardTrigger className="flex gap-1 items-center">
              <NotepadText className="h-4 w-4" />
              <p>Description</p>
            </HoverCardTrigger>
            <HoverCardContent className="w-96">
              <p>{facility.name}</p>
              <p className="text-sm">
                {facility.description}
              </p>
            </HoverCardContent>
          </HoverCard>
        : <p className="line-clamp-3">{facility.description}</p>
      : <></>}
        <GenericDialog facility={facility} key={`dialog-${facility.id}`} />
    </div>
  )
}
