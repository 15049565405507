interface BreakdownItem {
  day: string;
  date: string;
  price: string;
}

interface Item {
  name: string;
  value: string;
  breakdown?: BreakdownItem [];
  isNegative: boolean;
}

export const OrderSummary = ({ items, amount }: { items: Item[], amount: string }) => {
  return (
    <dl>
      {items.length > 0 && items?.map((item: any, i: number) => (
        <dd className="flex flex-col justify-between" key={`${item}-${i}`}>
          <span className="flex justify-between">
            <span>{item.name}</span>
            <span>{item.isNegative && "- "}${item.value}</span>
          </span>
          {item?.breakdown?.map((breakdown: BreakdownItem, i: number) => (
            <span className="flex justify-between" key={i}>
              <span className="px-2">• {breakdown.date}</span>
              <span>${breakdown.price}</span>
            </span>
          ))}
        </dd>
      ))}
      <hr className="mt-2" />
      <dd className="flex justify-between mt-2">
        <span>Total: </span>
        <span className="font-semibold">${amount}</span>
      </dd>
    </dl>
  )
}
