import { Badge } from "@/components/ui/badge"
import { CircleOff } from "lucide-react"
import { cn } from "@/lib/utils"
import { Tag } from "@/api/types/internal/Tag"

export const Badges = (tags: Tag[], classes?: string, variant?: string) => {
  if (tags?.length === 0) return null
  const slicedTags = tags?.slice(0, 2)

  return (
    <>
      {slicedTags?.map((tag, i) => (
        <Badge
          variant={variant}
          key={`${tag.name}-${i}`}
          className={cn(
            "capitalize mr-1 font-normal text-xs",
            tag.name === "no overnight" && "bg-red-500 text-white strikethrough",
            classes
          )}
        >
          {tag.name}
        </Badge>
      ))}
    </>
  )
}
